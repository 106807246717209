<template>
  <div class="app" @scroll="handleScroll">
    <div class="search-container" :class="{ hidden: isScrollingDown }">
      <input
        v-model="searchQuery"
        @input="updateVisibleMovies"
        class="search-input"
        placeholder="Search movies..."
      />
      <button v-if="searchQuery" class="clear-button" @click="clearSearch">X</button>
    </div>

    <div v-if="searchQuery" class="carousel">
      <transition-group name="movie-slide" tag="div">
        <div
          v-for="(movie, index) in visibleMovies"
          :key="movie.id"
          class="movie-container"
          :style="movieContainerStyle(index)"
          @touchstart="startSwipe"
          @touchend="endSwipe"
          @touchmove.prevent
        >
          <div class="image-container">
            <img
              class="cover-photo"
              :src="`https://image.tmdb.org/t/p/w500${movie.poster_path}`"
              alt="Movie Cover"
            />
            <div class="overlay">
              <div class="last-played-message" v-if="movie.showLastPlayedMessage">
                This is the last movie you played
              </div>
              <button
                class="play-button"
                @click.stop="playMovie(movie)"
                v-if="!movie.isPlaying"
              >
                Play Movie
              </button>
              <div class="message" v-if="movie.isPlaying">
                {{ movie.message }}
              </div>
              <div class="movie-info">
                <h1 class="movie-title">{{ movie.title }} ({{ new Date(movie.release_date).getFullYear() }})</h1>
                <p class="movie-rating">Rating: {{ movie.vote_average }}</p>
                <p class="movie-description">{{ movie.overview }}</p>
                <p class="cast">Cast: {{ movie.cast }}</p>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>

    <div v-else class="carousel">
      <transition-group name="movie-slide" tag="div">
        <div
          v-for="(movie, index) in visibleMovies"
          :key="movie.id"
          class="movie-container"
          :style="movieContainerStyle(index)"
          @touchstart="startSwipe"
          @touchend="endSwipe"
          @touchmove.prevent
        >
          <div class="image-container">
            <img
              class="cover-photo"
              :src="`https://image.tmdb.org/t/p/w500${movie.poster_path}`"
              alt="Movie Cover"
            />
            <div class="overlay">
              <div class="last-played-message" v-if="movie.showLastPlayedMessage">
                This is the last movie you played
              </div>
              <button
                class="play-button"
                @click="playMovie(movie)"
                v-if="!movie.isPlaying"
              >
                Play Movie
              </button>
              <div class="message" v-if="movie.isPlaying">
                {{ movie.message }}
              </div>
              <div class="movie-info">
                <h1 class="movie-title">{{ movie.title }} ({{ new Date(movie.release_date).getFullYear() }})</h1>
                <p class="movie-rating">Rating: {{ movie.vote_average }}</p>
                <p class="movie-description">{{ movie.overview }}</p>
                <p class="cast">Cast: {{ movie.cast }}</p>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>

    <div v-if="showPlayer" class="player-overlay">
      <div class="player-info">
        <h1 class="player-title">{{ currentMovie.title }} ({{ new Date(currentMovie.release_date).getFullYear() }})</h1>
        <p class="player-rating">Rating: {{ currentMovie.vote_average }}</p>
      </div>
      <iframe
        class="movie-iframe"
        :src="movieIframeSrc"
        @load="loading = false"
        allowfullscreen
      ></iframe>
      <button class="back-button" @click="closePlayer">Back</button>
    </div>

    <div v-if="loading" class="loading">
      Loading...
      <div v-if="loadingMessage" class="loading-message">{{ loadingMessage }}</div>
    </div>

    <div v-if="searchQuery && visibleMovies.length > 0" class="swipe-indicator">
      Swipe to see more
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, onBeforeUnmount } from "vue";

export default {
  setup() {
    const movies = ref([]);
    const currentMovieIndex = ref(0);
    const showPlayer = ref(false);
    const loading = ref(false);
    const loadingMessage = ref("");
    const movieIframeSrc = ref("");
    const searchQuery = ref("");
    const swipeStartY = ref(0);
    const swipeEndY = ref(0);
    const page = ref(1);
    const totalPages = ref(1);
    const currentMovie = ref({});
    const isScrollingDown = ref(false);
    let lastScrollTop = 0;


    const genres = [28, 12, 16, 35, 80, 99, 18, 10751, 14, 36]; // Example genre IDs

    const fetchMovies = async (page = 1) => {
      const trendingUrl = `https://api.themoviedb.org/3/trending/movie/week?api_key=bff1bdab65d8042fd0cf561ea5427ac7&page=${page}`;
      const genreUrls = genres.map(
        (genre) => `https://api.themoviedb.org/3/discover/movie?api_key=bff1bdab65d8042fd0cf561ea5427ac7&with_genres=${genre}&page=${page}`
      );

      const [trendingResponse, ...genreResponses] = await Promise.all([
        fetch(trendingUrl),
        ...genreUrls.map((url) => fetch(url)),
      ]);

      const trendingData = await trendingResponse.json();
      const genreData = await Promise.all(genreResponses.map((response) => response.json()));

      totalPages.value = Math.max(trendingData.total_pages, ...genreData.map((data) => data.total_pages));

      const newMovies = await Promise.all(
        [
          ...trendingData.results,
          ...genreData.flatMap((data) => data.results.slice(0, 10)),
        ].map(async (movie) => ({
          ...movie,
          cast: await fetchMovieCast(movie.id),
          isPlaying: false,
          message: "",
          showLastPlayedMessage: false,
        }))
      );

      if (page === 1) {
        movies.value = newMovies;
      } else {
        movies.value = [...movies.value, ...newMovies];
      }
      updateVisibleMovies(); // Ensure visibleMovies is updated after fetching movies
      showLastPlayedMovie(); // Show the last played movie if available
    };

    const fetchMovieCast = async (movieId) => {
      const response = await fetch(
        `https://api.themoviedb.org/3/movie/${movieId}/credits?api_key=bff1bdab65d8042fd0cf561ea5427ac7`
      );
      const data = await response.json();
      return data.cast.slice(0, 5).map((actor) => actor.name).join(", ");
    };

    const fetchSearchMovies = async (query, page = 1) => {
      const searchUrl = `https://api.themoviedb.org/3/search/movie?api_key=bff1bdab65d8042fd0cf561ea5427ac7&query=${query}&page=${page}`;
      const response = await fetch(searchUrl);
      const data = await response.json();
      return await Promise.all(
        data.results.slice(0, 10).map(async (movie) => ({
          ...movie,
          cast: await fetchMovieCast(movie.id),
          isPlaying: false,
          message: "",
          showLastPlayedMessage: false,
        }))
      );
    };

    const visibleMovies = ref([]);

    const updateVisibleMovies = async () => {
      if (searchQuery.value) {
        visibleMovies.value = await fetchSearchMovies(searchQuery.value);
      } else {
        visibleMovies.value = movies.value;
      }
      currentMovieIndex.value = 0; // Reset to the first result
    };

    const clearSearch = () => {
      searchQuery.value = "";
      updateVisibleMovies();
    };

    const playMovie = (movie) => {
      loading.value = true;
      loadingMessage.value = ""; // Reset loading message
      setTimeout(() => {
        loadingMessage.value = "Some movies take up to 60 seconds to load. If the movie is not available on the web, it may not be able to load.";
      }, 5000);
      movieIframeSrc.value = `http://c.gizmobezo.com/play.php?movieId=${movie.id}`;
      showPlayer.value = true;
      currentMovie.value = movie;
      window.history.pushState(null, null, window.location.href); // Push state to history

      // Store the last played movie in local storage
      localStorage.setItem("lastPlayedMovieId", movie.id);
    };

    const closePlayer = () => {
      showPlayer.value = false;
      movieIframeSrc.value = "";
      loading.value = false; // Hide loading screen
    };

    const startSwipe = (e) => {
      swipeStartY.value = e.touches[0].clientY;
    };

    const endSwipe = (e) => {
      swipeEndY.value = e.changedTouches[0].clientY;
      handleSwipe();
    };

    const handleSwipe = () => {
      const distance = swipeStartY.value - swipeEndY.value;
      if (Math.abs(distance) > 100) {
        if (distance > 0) nextMovie();
        else prevMovie();
      }
    };

    const nextMovie = () => {
      if (currentMovieIndex.value < visibleMovies.value.length - 1) {
        currentMovieIndex.value++;
      }
    };

    const prevMovie = () => {
      if (currentMovieIndex.value > 0) {
        currentMovieIndex.value--;
      }
    };

    const movieContainerStyle = (index) => ({
      transform: `translateY(${
        (index - currentMovieIndex.value) * 100
      }vh)`,
      transition: "transform 0.5s ease-in-out",
    });

    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      isScrollingDown.value = scrollTop > lastScrollTop;
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;

      const bottom =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight;
      if (bottom && page.value < totalPages.value) {
        page.value++;
        fetchMovies(page.value);
      }
    };

    const showLastPlayedMovie = () => {
      const lastPlayedMovieId = localStorage.getItem("lastPlayedMovieId");
      if (lastPlayedMovieId) {
        const lastPlayedMovie = visibleMovies.value.find(movie => movie.id === parseInt(lastPlayedMovieId));
        if (lastPlayedMovie) {
          lastPlayedMovie.showLastPlayedMessage = true;
          setTimeout(() => {
            lastPlayedMovie.showLastPlayedMessage = false;
          }, 5000);
          // Scroll to the last played movie
          const index = visibleMovies.value.indexOf(lastPlayedMovie);
          currentMovieIndex.value = index;
        }
      }
    };

    onMounted(async () => {
      await fetchMovies();
      window.addEventListener("popstate", closePlayer); // Listen for back button
      window.addEventListener("scroll", handleScroll); // Listen for scroll events

      // Hide loading screen after 60 seconds
      setTimeout(() => {
        loading.value = false;
      }, 60000);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("popstate", closePlayer); // Clean up event listener
      window.removeEventListener("scroll", handleScroll); // Clean up scroll event listener
    });

    watch(searchQuery, () => {
      page.value = 1;
      updateVisibleMovies();
    });

    return {
      visibleMovies,
      showPlayer,
      loading,
      loadingMessage,
      movieIframeSrc,
      searchQuery,
      startSwipe,
      endSwipe,
      playMovie,
      closePlayer,
      movieContainerStyle,
      clearSearch,
      handleScroll,
      currentMovie,
      isScrollingDown,
    };
  },
};
</script>

<style scoped>
@import './App.css';

</style>